@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'PlusJakartaSansSemiBold';
    src: local("PlusJakartaSans-SemiBold"),
    url(/src/assets/Fonts/PlusJakartaSans-SemiBold.ttf) format('truetype'),
   
  }

  @font-face {
    font-family: 'PlusJakartaSansRegular';
    src: local("PlusJakartaSans-Regular"),
    url(/src/assets/Fonts/PlusJakartaSans-Regular.ttf) format('truetype'),
   
  }

  @font-face {
    font-family: 'PlusJakartaSansMedium';
    src: local("PlusJakartaSans-Medium"),
    url(/src/assets/Fonts/PlusJakartaSans-Medium.ttf) format('truetype'),
   
  }

  @font-face {
    font-family: 'PlusJakartaSansLight';
    src: local("PlusJakartaSans-Light"),
    url(/src/assets/Fonts/PlusJakartaSans-Light.ttf) format('truetype'),
   
  }

  @font-face {
    font-family: 'PlusJakartaSansExtraLight';
    src: local("PlusJakartaSans-ExtraLight"),
    url(/src/assets/Fonts/PlusJakartaSans-ExtraLight.ttf) format('truetype'),
   
  }

  @font-face {
    font-family: 'PlusJakartaSansExtraBold';
    src: local("PlusJakartaSans-ExtraBold"),
    url(/src/assets/Fonts/PlusJakartaSans-ExtraBold.ttf) format('truetype'),
   
  }

  @font-face {
    font-family: 'PlusJakartaSansBold';
    src: local("PlusJakartaSans-Bold"),
    url(/src/assets/Fonts/PlusJakartaSans-Bold.ttf) format('truetype'),
   
  }

  @font-face {
    font-family: 'HelveticaNowDisplayBold';
    src: local("HelveticaNowDisplay-Bold"),
    url(/src/assets/Fonts/HelveticaNowDisplay-Bold.ttf) format('truetype'),
   
  }

  