/* HorizontalListView.css */


.horizontal-list {
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow-x: auto;                    /* Enable horizontal scrolling */
  white-space: nowrap;                 /* Prevent items from wrapping */
  width: 100%;
  -webkit-overflow-scrolling: touch;   /* Smooth scrolling on touch devices */
  scrollbar-width: thin;               /* For Firefox: make the scrollbar thinner */
}

.horizontal-list-item {
  flex: 0 0 auto;                      /* Prevent flex items from shrinking */
  min-width: 100px;                    /* Minimum width for each item */
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}


.horizontal-listnew {
  display: flex;
  flex-direction: row;
  gap: 5px;
  
  scroll-behavior: smooth;
  overflow-x: auto;                    /* Enable horizontal scrolling */
  white-space: nowrap;                 /* Prevent items from wrapping */
  width: 100%;
  -webkit-overflow-scrolling: touch;   /* Smooth scrolling on touch devices */
  scrollbar-width: thin;               /* For Firefox: make the scrollbar thinner */
  scrollbar-color: transparent transparent; /* Initially hide scrollbar for Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Edge */
.horizontal-listnew::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Show scrollbar on hover for Chrome, Safari, Edge */
.horizontal-listnew:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Style for the scrollbar track */
.horizontal-listnew::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

/* Style for the scrollbar thumb (the draggable part) */
.horizontal-listnew::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Show scrollbar on hover for Firefox */
.horizontal-listnew:hover {
  scrollbar-color: #888 #f1f1f1; /* #888 is the thumb color, #f1f1f1 is the track color */
  scrollbar-width: thin;
}