/* Wrapper for the custom dropdown */
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100px;
}

/* Button to toggle the dropdown */
.custom-dropdown-toggle {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border: 0px solid #ccc;
  border-radius: 4px;
  width: 100%;  /* Set width to full */
  text-align: left;
  position: relative;
}

/* Container for dropdown options */
.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  width: 120%;
  margin: 20px;
  margin-top: 5px;
  border: 2px solid rgba(13, 13, 13, 0.10);
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none; /* Initially hidden */
}

.custom-dropdown-menu1 {
  position: absolute;
  top: 100%;
  width: 110%;
  height: 400px; /* Set custom height */
  margin: 10px;
  margin-top: 5px;
  border: 2px solid rgba(13, 13, 13, 0.10);
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none; /* Initially hidden */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
}

/* Visible state for dropdown menu */
.custom-dropdown-menu1.show {
  display: block; /* Display when the dropdown is open */
}

/* Visible state for dropdown menu */
.custom-dropdown-menu.show {
  display: block; /* Display when the dropdown is open */
}

/* Styling for each dropdown option */
.custom-dropdown-option {
  padding: 10px;
  cursor: pointer;
}

/* Hover effect for options */
.custom-dropdown-option:hover {
  background-color: #f1f1f1;
}

/* Additional styling for selected option */
.custom-dropdown-option.selected {
  background-color: #2A7A5A;
  color: white;
}

