
@import url('https://fonts.googleapis.com/css2?family=Helvetica+Now+Display:wght@400;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Custom class to hide scrollbars */
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}



/* Hide scrollbar */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* For WebKit-based browsers (Chrome, Safari) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Show scrollbar on focus */
.scrollbar-show:focus {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

.scrollbar-show:focus::-webkit-scrollbar {
  display: block; /* Chrome and Safari */
}



/* Custom scrollbar width for WebKit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Custom scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

/* Custom scrollbar handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Handle color */
  border-radius: 10px; /* Roundness */
  border: 1px solid #f1f1f1; /* Border color */
}

/* Firefox custom scrollbar width */
.custom-scrollbar {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* Handle color and track color */
}

/* ProgressBar.css */
.progress-bar-container {
  width: 100%;
  max-width: 500px; /* Adjust based on your design */
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  /* background-color: #3A7D50; Green color from the image */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 5px;
  transition: width 1s ease-out;
  /* transition: "width 1s ease-out", */
}

.progress-text {
  color: white;
  
  margin-right: 10px;
}

.gauge-container {
  display: flex;
  justify-content: start; /* Center the content horizontally */
  align-items: center; /* Center the content vertically if the container has a height */

 /* Ensure full height for vertical centering */
  margin: 0;
  padding: 0;
}

/* Loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
}



/* FloatingButton.css */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3A7D50; /* Background color */
  color: white; /* Text (icon) color */
  border: none;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor: pointer; */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.floating-button:hover {
  background-color: #2E5F3B; /* Darken background on hover */
  transform: scale(1.1); /* Slightly increase size on hover */
}

.icon {
  font-size: 32px; /* Icon size */
}


/* GridWithPopupMenu.css */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.grid-itemValut {
  position: relative;
  padding: 20px;
  /* background-color: #2A7A5A; */
  border-radius: 8px;
  text-align: center;
}

/* For small screens (e.g., mobile devices) */
@media (max-width: 320px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
}

/* For small screens (e.g., mobile devices) */
/* @media (max-width: 640px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    padding: 1px;
  }
} */

/* @media screen and (min-width: 2560px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    gap: 30px; 
    padding: 40px; 
  }
} */

/* @media screen and (min-width: 3840px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); 
    gap: 40px; 
    padding: 50px; 
  }
} */




.grid-containerValut {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 30px;
  padding: 20px;
}


.popup-menuValut {
  position: absolute;
   /*top: 100px; Adjust based on item size */
  right: 5;
  background-color: white;
  border: 0px solid #ccc;
  border-radius: 12px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
  padding: 12px 16px;
}

.grid-item {
  position: relative;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-trigger {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-menu {
  position: absolute;
  top: 50px; /* Adjust based on item size */
  right: 5;
  background-color: white;
  border: 0px solid #ccc;
  border-radius: 12px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
  padding: 12px 16px;
}


.grid-containerValutdetails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* For small screens (e.g., mobile devices) */
@media (min-width: 320px) {
  .grid-containerValutdetails {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
}



/* For small screens (e.g., mobile devices) */
/* @media (min-width: 420px) {
  .grid-containerValutdetails {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    padding: 10px;
  }
} */

/* @media (min-width: 768px) {
  .grid-containerValutdetails {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    padding: 1px;
  }
} */

/* @media screen and (min-width: 2560px) {
  .grid-containerValutdetails {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    gap: 30px; 
    padding: 40px; 
  }
} */

/* @media screen and (min-width: 3840px) {
  .grid-containerValutdetails {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); 
    gap: 40px; 
    padding: 50px; 
  }
} */

.grid-itemValutdetails {
  position: relative;
  padding: 20px 10px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
}



.grid-containeritemsugginv {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  
}

@media (max-width: 320px) {
  .grid-containeritemsugginv {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 10px;
  }
}

.grid-itemsugginv {
  position: relative;
  background-color: #ffffff;
 
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

}

.popup-menuValutdetails {
  position: absolute;
  top: 50px; /* Adjust based on item size */
  right: 5;
  background-color: white;
  border: 0px solid #ccc;
  border-radius: 12px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
  padding: 12px 16px;
}




/* .popup-menu ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.popup-menu ul li {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.popup-menu ul li:last-child {
  border-bottom: none;
}

.popup-menu ul li:hover {
  background-color: #f0f0f0;
} */

/* 
<div
  style={{
    width: `${progress}%`,
    height: "100%",
    backgroundColor:
      progress < 100 ? "#76c7c0" : "#4caf50",
    transition: "width 1s ease-out",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontWeight: "normal",
  }}
> 
<div
style={{
  width: "100%",
  height: "30px",
  backgroundColor: "#e0e0df",
  borderRadius: "5px",
  overflow: "hidden",
  position: "relative",
}}
>
*/


/* DoubleSlider.css */
/* .slider-container {
  width: 400px;
  margin: 50px auto;
  text-align: center;
}

.horizontal-slider {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin: 20px 0;
  position: relative;
}

.track {
  background-color: #3A7D50;
  height: 100%;
}

.thumb {
  height: 20px;
  width: 20px;
  background-color: #3A7D50;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
} */


/*  */


.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #D9DFE6;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #2A7A5A;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #2A7A5A;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #2A7A5A;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}


/* // */


.twitter {
  transform: translateX(164px) rotate(400deg);
  background-color: #55acee;
}

.facebook {
  transform: translateX(100px) rotate(300deg);
   background-color: #3b5998;
}

.instagram {
  transform: translateX(50px) rotate(200deg);
  background-color: #3f729b;
}

.mail {
  transform: translateX(0);
  background-color: #673AB7;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  	-webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}


html {
  height: 100%;
  font-family: "Raleway";
}

.share {
  position: absolute; 

  transform: translateY(-50%);
  height: auto;
  margin: 0 auto;
  
    &:hover {
      
      .share__icon {
        transform: none;
        opacity: 1;
        z-index: 2;
      }
      
      .share__icon--button {
        max-width: 52px;
        opacity: 0;
        z-index: -1;
      }
      
      
    }
 
  
    
    .share__icon {
      display: inline-block;
      font-size: 1.6em;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      text-align: center;
      opacity: 0;
      transition: transform 0.9s cubic-bezier(.41,1.26,.56,.99),
                  rotate 0.9s cubic-bezier(.41,1.26,.56,.99);
      z-index: 5;
    }
    
    a {
      display: block;
      color: white;
    }
    
    i {
      line-height: 50px;
    }
    
  
  .share__icon--button {
    position: absolute;
    right: 0;
    opacity: 1;
    width: 100%;
    max-width: 300px;
    border-radius: 50px;
    transition: all 0.5s;
    background-color: #FFC107;
    
    a {
     line-height: 50px;
     text-decoration: none;
    }
    
  }
 
}



